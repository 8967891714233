import React from "react";
import DownloadAppZh from "./components/DownloadAppZh";

export default function Zh() {
  return (
    <div className="wrapper">
      <div className="container p-5">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <h1 className="mb-4">VennChat</h1>
          <DownloadAppZh />
        </div>

        <h1
          className="text-center animate__animated animate__fadeIn"
          style={{ margin: "140px 0px" }}
        >
          轻松愉快聊天， <br />
          随时随地社交，
          <br />
          探索无限可能。
        </h1>

        <h4 className="text-center p-3 animate__animated animate__fadeIn">
          VennChat是一个充满活力的社交媒体平台，
          帮助用户促成有意义的互动和关系。 通过与值得信赖的应用程序发布商合作，
          VennChat创造了丰富的个人成长、领导力发展以及
          在崭新而令人兴奋的社交媒体领域内进行探索的机会。
        </h4>

        <h1
          className="text-center"
          style={{
            marginTop: "120px",
            color: "#DD9BA4",
          }}
        >
          关于VennChat
        </h1>

        <h4 className="text-center my-5 py-5">
          一款尖端的私人消息平台， <br />
          强调有效的连接和用户安全。 <br />
          安全而无缝沟通的终极选择。
        </h4>

        {/* intro 1  */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ marginBottom: "200px", marginTop: "100px" }}
        >
          <div className="w-100 w-sm-50">
            <h3 className="my-4">高效的聊天服务</h3>
            <h2 className="my-5">聊天</h2>
            <p>
              轻松实时与朋友和家人聊天，分享链接、照片、视频，甚至语音录音。
            </p>
          </div>
          <div className="w-100 w-sm-50 text-center p-5">
            <img width={200} src="images/1.svg" alt="" />
          </div>
        </div>

        {/* intro 2  */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ margin: "200px 0px" }}
        >
          <div className="w-100 w-sm-50 text-center p-5">
            <img width={200} src="images/2.svg" alt="" />
          </div>
          <div className="w-100 w-sm-50">
            <h3 className="my-4">探索引人入胜的朋友圈</h3>
            <h2 className="my-5">发现</h2>
            <p>
              与朋友保持联系，获取实时更新，探索引人入胜的内容，享受无缝的浏览体验。全方位和你的朋友圈的内容进行互动。
            </p>
          </div>
        </div>

        {/* intro 3 */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ margin: "200px 0px" }}
        >
          <div className="w-100 w-sm-50">
            <h3 className="my-4">发现新的社区。</h3>
            <h2 className="my-5">群组</h2>
            <p>
              与志同道合的个体联系，拓展您的网络，合作并探索多样化的思想。获得有价值的见解，发现与您兴趣相符的新群体，并加入充满活力的社区，拓展您的视野，探索新的机遇。
            </p>
          </div>
          <div className="w-100 w-sm-50 text-center p-5">
            <img width={200} src="images/3.svg" alt="" />
          </div>
        </div>

        {/* intro 4  */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ margin: "200px 0px" }}
        >
          <div className="w-100 w-sm-50 text-center">
            <img width={200} src="images/4.svg" alt="" />
          </div>
          <div className="w-100 w-sm-50">
            <h3 className="my-4">个性化您的个人资料</h3>
            <h2 className="my-5">主页</h2>
            <p>
              定制您的个人资料页面，展示您独特的个性、风格和成就。掌握个人信息和隐私设置，确保在线存在的安全与保密。
            </p>
          </div>
        </div>

        <div id="download">
          <h3
            style={{
              color: "#fff",
              marginBottom: "60px",
            }}
            className="text-center"
          >
            现在下载
          </h3>

          <DownloadAppZh />
        </div>
      </div>
    </div>
  );
}
