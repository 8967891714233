import React from "react";
import styled from "styled-components";

export default function NotificationInstructions() {
  return (
    <StyledWrapper>
      <div className="container">
        <h1>VennChat Troubleshooting Notifications</h1>

        <hr />

        <div className="section">
          <h2>⼩⽶</h2>
          <div>· ⼿机管家→应⽤管理→权限→⾃启动管理→允许万通⾃启动</div>
          <div>
            · 系统设置→省电与电池→右上⾓点击设置→应⽤智能省电→万通→选择⽆限制
          </div>
          <div>· 系统设置→通知与控制中⼼→通知管理→选择万通并允许通知</div>
        </div>

        <hr />

        <div className="section">
          <h2>Vivo</h2>
          <div>· i管家→应⽤管理→权限管理→选择权限⻚→⾃启动→允许万通⾃启动</div>
          <div>· 系统设置→通知与状态栏→应⽤通知管理→选择万桶并允许通知</div>
          <div>· 系统设置→电池→后台耗电管理→选择万通→允许后台⾼耗电</div>
        </div>

        <hr />

        <div className="section">
          <h2>三星</h2>
          <div>· 系统设置→通知→应⽤程序通知→选择万通→允许通知</div>
          <div>· 系统设置→电池→后台控制→选择万通→选择不受限制</div>
        </div>

        <hr />

        <div className="section">
          <h2>荣耀和华为</h2>
          <div>鸿蒙系统⽬前不⽀持</div>
        </div>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  background-color: #130f3f;
  padding: 20px;
  min-height: 100vh;
  color: #fff;

  .section {
    margin: 20px;
    div {
      font-size: 14px;
      font-weight: 400;
      padding: 10px 0px;
    }
  }
  h1 {
    font-size: 28px;
    margin: 20px 0px;
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  a {
    color: #d995fa;
  }
`;
