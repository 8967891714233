import React from "react";

import DownloadApp from "./DownloadApp";

export default function En() {
  return (
    <div className="wrapper">
      <div className="container p-5">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <h1 className="mb-4">VennChat</h1>
          <DownloadApp />
        </div>

        <h1
          className="text-center animate__animated animate__fadeIn"
          style={{ margin: "140px 0px" }}
        >
          Effortless Chat, <br /> Seamless Connection, <br /> Unlimited
          Discovery
        </h1>

        <h4 className="text-center p-3 animate__animated animate__fadeIn">
          VennChat is a dynamic social media platform that connects users,
          leading to meaningful interactions and relationships. By collaborating
          with trusted application publishers, VennChat creates abundant
          opportunities for personal growth, leadership development, and
          exploration within a fresh and exciting social media landscape.
        </h4>

        <h1
          className="text-center"
          style={{
            marginTop: "120px",
            color: "#DD9BA4",
          }}
        >
          About VennChat
        </h1>

        <h4 className="text-center my-5 py-5">
          A cutting-edge private messaging platform that emphasizes effective
          <br />
          connection and user security. The ultimate choice for secure and
          <br />
          seamless communication.
        </h4>

        {/* intro 1  */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ marginBottom: "200px", marginTop: "100px" }}
        >
          <div className="w-100 w-sm-50">
            <h3 className="my-4">Efficient chat service</h3>
            <h2 className="my-5">Chats</h2>
            <p>
              Effortlessly connect with friends and family in real-time. Share
              links, photos, videos, and even voice recordings.
            </p>
          </div>
          <div className="w-100 w-sm-50 text-center p-5">
            <img width={200} src="images/1.svg" alt="" />
          </div>
        </div>

        {/* intro 2  */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ margin: "200px 0px" }}
        >
          <div className="w-100 w-sm-50 text-center p-5">
            <img width={200} src="images/2.svg" alt="" />
          </div>
          <div className="w-100 w-sm-50">
            <h3 className="my-4">Explore captivating posts</h3>
            <h2 className="my-5">Discovery</h2>
            <p>
              Stay connected with friends, receive real-time updates, explore
              engaging content, and enjoy a seamless browsing experience. Enjoy
              captivating posts from your friends in a single, convenient
              location.
            </p>
          </div>
        </div>

        {/* intro 3 */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ margin: "200px 0px" }}
        >
          <div className="w-100 w-sm-50">
            <h3 className="my-4">Discover new communities</h3>
            <h2 className="my-5">Groups</h2>
            <p>
              Connect with like-minded individuals, expand your network,
              collaborate, and explore diverse ideas. Gain valuable insights,
              discover new groups that align with your interests, and join
              vibrant communities to expand your horizons and explore new
              opportunities
            </p>
          </div>
          <div className="w-100 w-sm-50 text-center p-5">
            <img width={200} src="images/3.svg" alt="" />
          </div>
        </div>

        {/* intro 4  */}
        <div
          className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center"
          style={{ margin: "200px 0px" }}
        >
          <div className="w-100 w-sm-50 text-center">
            <img width={200} src="images/4.svg" alt="" />
          </div>
          <div className="w-100 w-sm-50">
            <h3 className="my-4">Personalize your profile</h3>
            <h2 className="my-5">Profile</h2>
            <p>
              Customize your personal profile page to showcase your unique
              personality, style, and accomplishments. Take control of your
              personal information and privacy settings to ensure a safe and
              secure online presence.
            </p>
          </div>
        </div>

        <div id="download">
          <h3
            style={{
              color: "#fff",
              marginBottom: "60px",
            }}
            className="text-center"
          >
            Download now
          </h3>

          <DownloadApp />
        </div>
      </div>
    </div>
  );
}
