import React, { useEffect, useState } from "react";
import Zh from "./components/Zh";
import En from "./components/En";

function App() {
  const [lan, setLan] = useState("us-En");

  useEffect(() => {
    // 获取浏览器语言
    // @ts-ignore
    const userLanguage = navigator.language || navigator.userLanguage;
    setLan(userLanguage);
  }, []);

  return ["zh-CN", "zh-HK", "zh-TW"].includes(lan) ? <Zh /> : <En />;
}

export default App;
