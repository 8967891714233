import React from "react";

export default function DownloadApp() {
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
      <a href="https://apps.apple.com/ca/app/vennchat/id6443394582">
        <h4
          style={{
            textAlign: "center",
            color: "#fff",
            padding: "16px 30px 10px 30px",
            border: "1px solid #6724FD",
            background: "#6724FD",
            borderRadius: "100px",
            margin: "20px 60px",
            fontSize: "18px",
          }}
        >
          App Store
        </h4>
      </a>

      <a href="https://play.google.com/store/apps/details?id=ca.richlink.iment">
        <h4
          style={{
            textAlign: "center",
            color: "#fff",
            padding: "16px 30px 10px 30px",
            border: "1px solid #6724FD",
            background: "#6724FD",
            borderRadius: "100px",
            margin: "20px 60px",
            fontSize: "18px",
          }}
        >
          Google Play
        </h4>
      </a>

      <div>
        <a href="https://vhot-android-apk.s3.us-west-2.amazonaws.com/Vennchat_5.6.1-play-release-5.6.1.apk">
          <h4
            style={{
              width: "210px",
              textAlign: "center",
              color: "#fff",
              padding: "16px 30px 10px 30px",
              border: "1px solid #6724FD",
              background: "#6724FD",
              borderRadius: "100px",
              margin: "20px 60px",
              fontSize: "18px",
            }}
          >
            Chinese Mobile (Android)
          </h4>
        </a>
        <a href="/notification-instructions">
          <p className="text-center text-white">
            Push notification <br /> settings instructions
          </p>
        </a>
      </div>
    </div>
  );
}
